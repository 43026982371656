.App {
  text-align: center;
  background-color: #F8FAFD;
  font-family: 'Poppins','Open Sans', 'Roboto', sans-serif; /* Use the chosen font family */
}

.navbar{
  box-sizing: border-box;
  display: flex;
  justify-content: space-between !important;
  flex-direction: row;
  align-items: center;
  padding: 0px 32px 0px 0px;
  width: 100%;
  height: 70px;
  background: #0b2239;
  border-bottom: 2px solid #e1e5e7;
  top: 0;
  position: fixed;
  z-index: 999;
}

.navbar .title{
  margin-left: 110px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  /* display: flex; */
  align-items: center;
  color: white;
  margin-top: 20px;
}

.navbar .navItems{
  display: flex;
  flex-direction: row;
  gap: 60px;
  margin-right: 110px;
  font-size: large;
  font-weight: 600;
  margin-top: 20px;
}
.item{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.mainBody{
  padding: 90px 120px 40px 120px;
  background-color: #edf0f5;
  min-height: 100vh ;
  /* background-image: url("../public/images/backgroundMain.jpg"); */
  background-size: cover; /* Adjust to your needs */
    background-repeat: no-repeat;
    background-position: center center;
    /* filter: blur(8px);
    z-index: -1; */
}

.schoolInfo{
  min-height: 250px;
  border: 1px solid #e1e5e7;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  background-color: white;
}

.schoolImage{
  margin: 20px;
  width: 20vw;
  height: 30vh;
  /* background-color: bisque; */
}
.countCards{
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
.countCardsDashboard{
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}
.cardDashboard{
  width: 18vw;
  height: 80px;
  border: 1px solid #4383d1;
  border-radius: 8px;
  align-items:center;
  text-align: start;
  padding: 12px;
  background-color: #4383d1;
  color: white;
}
.card1{
  width: 24vw;
  height: 100px;
  border: 1px solid #4383d1;
  border-radius: 8px;
  align-items:center;
  text-align: start;
  padding: 12px;
  background-color: #4383d1;
  color: white;

}
.card2{
  width: 24vw;
  height: 100px;
  border: 1px solid #e0574d;
  border-radius: 8px;
  align-items:center;
  text-align: start;
  padding: 12px;
  background-color: #e0574d;
  color: white;
}
.card3{
  width: 24vw;
  height: 100px;
  border: 1px solid #53c759;
  border-radius: 8px;
  align-items:center;
  text-align: start;
  padding: 12px;
  background-color: #43a047;
  color: white;

}

.raiseTicket{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 20px 0px;
}

.raiseTicketPage{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 20px 0px;
}

.raiseBtn{
  padding: 12px;
  font-size: large;
  border-radius: 8px;
  border: 1px solid #1273eb;
  background-color: #1273eb;
  color:white;
  display: flex;
  align-items: center;
  gap: 10px;
}
.verifiedBtn{
  padding: 12px;
  font-size: large;
  border-radius: 8px;
  border: 1px solid #35ff78cc;
  background-color: #35ff78cc;
  color:#0eb646;
  display: flex;
  align-items: center;
  gap: 10px;
}
.selectOption{
  padding: 12px;
  font-size: large;
  border-radius: 8px;
  border: 2px solid #e1e5e7;
  /* background-color: #EA9E4F;
color: white */
}
.options{
  padding: 12px;
}
.information{
  text-align: start;
  margin: 24px;
}
.paraInfo{
  font-size: large;
  font-weight: 500;
  color: rgba(79, 79, 79, 1);
}
.paraInfoSmall{
  font-weight: 500;
  font-size: 16px;
  color: rgba(79, 79, 79, 1);
  margin-top: 12px;
}
.personalDetails{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.iconImage{
  /* background-color: #1273eb; */
  /* color: white; */
  padding: 6px;
  border: 2px solid #1273eb;
  border-radius: 8px;
  margin-right: 10px;
  height: 20px;
  margin-top: 0px;

}
.countsDashboard{
  font-size: 26px;
  font-weight: 600;
  margin: 0px 0px 00px 0px;
}
.counts{
  font-size:xx-large;
  font-weight: 500;
  margin: 0px 0px 10px 0px;
}

.createTicket{
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: white;
  border: 1px solid #e1e5e7;
  border-radius: 8px;
}

.messageContent{
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-top: 10px;
  margin-bottom: 20px;
}
.subjectContent{
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 12px;
  justify-content: space-between;
}
.item1{
  text-align: start;
  width: 60%;
}
.item2{
  text-align: start;
  width: 30%;
}

.subjectInput{
  border: 1px solid #e1e5e7;
  width: 100%;
  padding: 12px;
  border-radius: 8px;
}

.btnContainer{
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap:20px
}
.submitBtn{
  padding: 12px;
  font-size: large;
  /* font-weight: bold; */
  border-radius: 8px;
  border: 1px solid #1273eb;
  background-color: #1273eb;
  color:white;
  display: flex;
  align-items: center;
  
}
.cancelBtn{
  padding: 12px;
  font-size: large;
  /* font-weight: bold; */
  border-radius: 8px;
  border: 1px solid #e1e5e7;
  background-color: black;
  color:white;
  display: flex;
  align-items: center;
  /* gap: 10px; */
}


.custom-input {
  border-radius: 4px;
  padding: 10px 16px;
  width: 100%;
  margin-top: 5px;
  background-color: white;
  color: #333;
  border: 1px solid #ccc;
  transition: border-color 0.3s;
  outline: none;
  font-size: 14px;
}

.custom-input:focus {
  border-color: #4c9aff;
  box-shadow: 0 0 0 3px rgba(76, 154, 255, 0.25);
}

.custom-text {
  color: #6b7280;
  display: block;
  margin-top: 0.75rem; /* Equivalent to 12px */
}

.custom-button {
  margin-top: 1.5rem; /* Equivalent to 24px */
  padding: 12px 16px;
  width: 100%;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 0.25rem; /* Equivalent to 4px */
  cursor: pointer;
  background-image: linear-gradient(to right, #4f46e5, #4f46e5);
  transition: all 0.3s;
}

.custom-button:hover {
  background-image: linear-gradient(to right, #4338ca, #4338ca);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-button:focus {
  background-color: #4c1aff;
}

.custom-container {
  background-image: url("../public/images/backgroundMain.jpg");
    background-size: cover; /* Adjust to your needs */
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.custom-box {
  border-top: 8px solid #4f46e5;
  border-top-width: 8px;
  border-color: #4f46e5; /* Equivalent to border-indigo-600 */
  border-radius: 0.125rem; /* Equivalent to rounded-sm */
  background-color: white; /* Equivalent to bg-white */
  padding: 3rem; /* Equivalent to p-12 */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Equivalent to shadow-2xl */
  width: 24rem; 
}

.custom-heading {
  font-weight: bold;
  text-align: center;
  display: block;
  font-size: 1.5rem; /* Equivalent to text-2xl */
}

.footer {
  background-color: #0e2a47;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between !important;
  flex-direction: row;
  align-items: center;
  padding: 0px 32px;
  width: 100%;
  height: 40px;
  border-top: 2px solid #e1e5e7; /* Changed to border-top to stick it to the bottom */
  position: fixed;
  bottom: 0; /* Set to bottom */
  z-index: 999;
  color: white;
  font-weight: bold;
}

.custom-table {
  border: 1px solid #e1e5e7;
  /* border-collapse: separate; */
  /* border-spacing: 0 10px; Add spacing between rows */
  border-radius: 8px; /* Border radius of 8px */
  /* overflow: hidden; Clip the border-radius */
}

.custom-table th,
.custom-table td {
  padding: 10px 15px;
}

.custom-button1 {
  padding: 6px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.custom-button1:hover {
  background-color: #0056b3;
}

.respondContainer{
  background-color: white;
  border: 1px solid #e1e5e7;
  border-radius: 8px;
  text-align: start;
  padding: 24px;
}
.subjectHead{
  border: 1px solid #e1e5e7;
  padding: 10px;
  border-radius: 8px;
  font-weight: bold;
}
.respondBody{
  border: 1px solid #e1e5e7;
  border-radius: 8px;
  padding: 10px;

}
.statusContainer{
  padding: 10px;
  margin-top: 10px;
  gap:20px;
  display: flex;
  align-items: center;
}
.status{
  padding: 6px;
  border-radius: 8px;
  border: 1px solid #e1e5e7;

}
.responses{
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e1e5e7;
  margin-bottom: 10px;
}
.textArea{
  padding: 6px;
  border-radius: 8px;
  border: 1px solid #e1e5e7;
  width: 100%;
  min-height: 100px;
}

.institutionContainer{
  padding: 24px;
  border: 1px solid #e1e5e7;
  background-color: white;
  border-radius: 8px;
}